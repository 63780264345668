import Axios from 'axios';
import { BASE_URL } from '../constants/constants';
import { store } from '../storeConfig/store';
import { showLoader , hideLoader , showSuccessMsg , hideMessage , showErrorMsg } from '../actions/loader';
import {getCookieItem} from "../../helper/requestHelpers";
let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QtdXNlci0yQGJjYmVkLmJlIiwicGFzc3dvcmQiOiJBMUBzcXdlcnQifQ.kWI5lYK6DxuFLXzsqSbCBFyx5CaoVwNCSJJGX-aBI3E';
const axios = Axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers:{ 
    "Access-Control-Allow-Origin": "*" ,
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookieItem("access_token") || token}`
  }
});

const { dispatch } = store;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    dispatch(showLoader())
    return config;
  }, function (error) {
      console.log('requestError')
      dispatch(hideLoader())
      return Promise.reject(error);
  });

// // Add a response interceptor
axios.interceptors.response.use(function (response) {
    dispatch(hideLoader()) 
    dispatch(showErrorMsg(''))
    if(response.config.method != 'get') dispatch(showSuccessMsg(response.data.message))
    setTimeout(()=>
    {
    dispatch(hideMessage())
    },5000)
    return response;
  }, function (error) {
    const errorMsg = error.response && error.response.data ? error.response.data.message : "Unexpected error"
      dispatch(hideLoader())
      dispatch(showErrorMsg(errorMsg))
      setTimeout(()=>
      {
        dispatch(hideMessage())
      },5000)
    return Promise.reject(error);
  });

  export default axios;