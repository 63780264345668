import axios from '../axios/axios';
import { BASE_URL } 
from '../constants/constants';


export const getHeaderContent = () => {
  return async dispatch => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/app/info/module/location?module_location=top`);
    return data.result.data;
  }
}

export const getContentByModuleLocation = (moduleLocation) => {
  return async dispatch => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/app/info/module/location?module_location=${moduleLocation}`);
    return data.result.data;
  }
}

